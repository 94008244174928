import { differenceInHours } from "date-fns";

const operatorMapping = {
	">": (difference: number, duration: number): boolean => {
		return difference > duration;
	},
	"<=": (difference: number, duration: number): boolean => {
		return difference <= duration;
	},
};

/**
 * Checks if a given date is within a specified window of time based on an operator and duration.
 *
 * @param {string} date - The date to compare, in a string format parsable by `new Date`.
 * @param {string} operator - The comparison operator (e.g., `>` or `<=`) to use for evaluating the time window.
 * @param {number} duration - The duration in hours to compare the date against.
 * @returns {boolean} `true` if the date is within the specified window based on the operator and duration, otherwise `false`.
 *
 * @description
 * - Uses `differenceInHours` from `date-fns` to calculate the difference in hours between the given date and the current time.
 * - Evaluates the difference against the provided duration using the specified operator from `operatorMapping`.
 * - Returns `false` if the operator is not supported.
 *
 * @example
 * const result1 = isDateWithinWindow("2025-01-08T12:00:00Z", ">", 5);
 * console.log(result1); // true or false based on the time difference
 *
 * const result2 = isDateWithinWindow("2025-01-08T12:00:00Z", "<=", 48);
 * console.log(result2); // true or false based on the time difference
 *
 * const result3 = isDateWithinWindow("2025-01-08T12:00:00Z", "unsupported", 5);
 * console.log(result3); // false (unsupported operator)
 */
export const isDateWithinWindow = (date: string, operator: string, duration: number): boolean => {
	if (!operatorMapping[operator]) {
		return false;
	}

	return operatorMapping[operator](differenceInHours(new Date(date), new Date()), duration);
};
