/*  
	Commented out the affiliates which are currently not supported. 
	Uncomment if they are required for new features / experiments.
	This prevents users changing affiliate code via the URL.
	As we use GENIUS_AFFILIATES within isValidGeniusAffiliateCode().
*/

export const GENIUS_5_AFFILIATE_CODE = "genius5";
export const GENIUS_10_AFFILIATE_CODE = "genius10";
export const GENIUS_15_AFFILIATE_CODE = "genius15";
export const GENIUS_20_AFFILIATE_CODE = "genius20";

export const GENIUS_AFFILIATES = [
	GENIUS_10_AFFILIATE_CODE,
	// GENIUS_20_AFFILIATE_CODE,
	// GENIUS_5_AFFILIATE_CODE,
	// GENIUS_15_AFFILIATE_CODE,
];

export const GENIUS_TIERED_AFFILIATES = {
	genius5: 5,
	genius10: 10,
	genius15: 15,
	genius20: 20,
};
