import { GENIUS_AFFILIATES } from "../../constants/affiliate-codes";

/**
 * Checks if the provided affiliate parameter matches any of the valid Genius affiliate codes.
 *
 * @param {string} affiliateParam - The affiliate parameter to validate.
 * @returns {boolean} `true` if the affiliate parameter is valid, otherwise `false`.
 *
 * @description
 * - The function verifies if the `affiliateParam` exists in the predefined list of valid Genius affiliate codes (`GENIUS_AFFILIATES`).
 * - Converts the `affiliateParam` to a string (if necessary) before performing the validation.
 *
 * @example
 * // Assuming GENIUS_AFFILIATES = ["GENIUS_AFF1", "GENIUS_AFF2"]
 * console.log(isValidGeniusAffiliateCode("genius10")); // true
 * console.log(isValidGeniusAffiliateCode("discountPlease")); // false
 */
export const isValidGeniusAffiliateCode = (affiliateParam: string): boolean =>
	GENIUS_AFFILIATES.includes(affiliateParam?.toString());
