/**
 * Recursively replaces `null` and empty string fields in an object or array with `undefined`.
 *
 * @template T - The type of the input object or array.
 * @param {T} obj - The input object, array, or value to process.
 * @returns {T} The processed object, array, or value with `null` and empty string fields replaced with `undefined`.
 *
 * @description
 * - If the input is `null` or an empty string, it is replaced with `undefined`.
 * - If the input is an array, the function is called recursively on each element.
 * - If the input is an object, the function processes each key recursively.
 * - Other types are returned as-is.
 *
 * @example
 * // Example with an object
 * const obj = { a: null, b: "", c: 42, d: { e: null, f: "text" } };
 * const result = replaceNullAndEmptyFieldsWithUndefined(obj);
 * console.log(result); // { a: undefined, b: undefined, c: 42, d: { e: undefined, f: "text" } }
 *
 * @example
 * // Example with an array
 * const arr = [null, "", 42, { a: null }];
 * const result = replaceNullAndEmptyFieldsWithUndefined(arr);
 * console.log(result); // [undefined, undefined, 42, { a: undefined }]
 */
export const replaceNullAndEmptyFieldsWithUndefined = <T>(obj: T): T => {
	if (obj === null || obj === "") {
		return undefined;
	}

	if (typeof obj === "object") {
		if (Array.isArray(obj)) {
			return obj.map((item) => replaceNullAndEmptyFieldsWithUndefined(item)) as T;
		}

		const newObj = {} as T;
		// eslint-disable-next-line no-restricted-syntax
		for (const key in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(key)) {
				newObj[key] = replaceNullAndEmptyFieldsWithUndefined(obj[key]);
			}
		}

		return newObj;
	}

	return obj;
};
