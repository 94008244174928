import type { BookingDetailsExpectedParameters } from "../../pages/BookingDetailsPage/BookingDetailsPage.types";
import { removeTrailingSlash } from "../removeTrailingSlash";

/**
 * Updates a search parameter in the current URL and replaces the browser's history state.
 *
 * @param {keyof BookingDetailsExpectedParameters} paramName - The name of the search parameter to update.
 * @param {string} paramValue - The value to set for the specified search parameter.
 * @returns {void}
 *
 * @description
 * - Uses `URLSearchParams` to set or update the specified parameter in the current URL's query string.
 * - Updates the browser's history state using `window.history.replaceState` without reloading the page.
 * - Ensures the URL's pathname does not end with a trailing slash by using `removeTrailingSlash`.
 *
 * @example
 * // Current URL: https://example.com/path/?foo=bar
 * updateSearchParam("baz", "qux");
 * // Updated URL: https://example.com/path/?foo=bar&baz=qux
 *
 * @example
 * // Current URL: https://example.com/path/?foo=bar
 * updateSearchParam("foo", "newvalue");
 * // Updated URL: https://example.com/path/?foo=newvalue
 */
export const updateSearchParam = (paramName: keyof BookingDetailsExpectedParameters, paramValue: string): void => {
	const currentSearchParams = new URLSearchParams(window.location.search);
	currentSearchParams.set(paramName, paramValue);

	window.history.replaceState(
		undefined,
		"",
		`${removeTrailingSlash(window.location.pathname)}/?${currentSearchParams.toString()}`,
	);
};
