import React, { useEffect } from "react";
import { useTaxiBookPageOrchestrator } from "../../hooks/useTaxiBookPageOrchestrator";
import type { Comments } from "../../orchestrator/TaxiBookPageOrchestrator";
import { updateSearchParam } from "../../utils/updateSearchParam";
import { deleteSearchParam } from "../../utils/deleteSearchParam";
import { SelectedVehicle } from "../../types/taxi-search-results";
import { Flight } from "../../types/flight-picker";

const SearchParamsHandler: React.FC = () => {
	const orchestrator = useTaxiBookPageOrchestrator();

	const commentsUpdatedHandler = (comment: Comments | undefined): void => {
		if (!comment?.encrypted) {
			deleteSearchParam("comments");
			return;
		}

		updateSearchParam("comments", comment.encrypted);
	};

	const selectedVehicleHandler = (selectedVehicle: SelectedVehicle | undefined): void => {
		if (!selectedVehicle?.carTypeResultReference) {
			deleteSearchParam("preSelectedResultReference");
			return;
		}

		updateSearchParam("preSelectedResultReference", selectedVehicle.carTypeResultReference.toString());
	};

	const flightInfoUpdatedHandler = (flightInfo: Flight | undefined): void => {
		if (!flightInfo?.flightNumber) {
			deleteSearchParam("flightNumber");
			return;
		}

		updateSearchParam("flightNumber", flightInfo.flightNumber);
		updateSearchParam("time", flightInfo.flightArrivalTime);
	};

	useEffect(() => orchestrator.onCommentsUpdated(commentsUpdatedHandler), [orchestrator]);
	useEffect(() => orchestrator.onSelectedVehicleUpdated(selectedVehicleHandler), [orchestrator]);
	useEffect(() => orchestrator.onFlightInfoUpdated(flightInfoUpdatedHandler), [orchestrator]);

	// We need to return something otherwise there won't be anything to render
	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <></>;
};

export { SearchParamsHandler };
