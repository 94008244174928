/**
 * Removes the trailing slash from a given URL string if it exists.
 *
 * @param {string} url - The URL string to process.
 * @returns {string} The URL without a trailing slash.
 *
 * @description
 * - Checks if the URL ends with a trailing slash (`/`) using a regular expression.
 * - If a trailing slash exists, it removes it by slicing the string.
 * - If there is no trailing slash, the original URL is returned unchanged.
 *
 * @example
 * const result1 = removeTrailingSlash("https://example.com/");
 * console.log(result1); // "https://example.com"
 *
 * const result2 = removeTrailingSlash("https://example.com/path/");
 * console.log(result2); // "https://example.com/path"
 *
 * const result3 = removeTrailingSlash("https://example.com");
 * console.log(result3); // "https://example.com" (unchanged)
 */
export const removeTrailingSlash = (url: string): string => {
	if (/\/$/g.test(url)) {
		return url.slice(0, -1);
	}

	return url;
};
