import { registerDynamicTags, t } from "@bookingcom/lingojs-core";

export const registerLingoTags = (): void => {
	registerDynamicTags(t("gt_mig_rides_action-bar_cta"));
	registerDynamicTags(t("gt_mig_rides_action-bar_price"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_a11y-close"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_add-request"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_baby-seat"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_baby-seat-description"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_baby-seat-lc-plural"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_baby-seat-lc-singular"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_booster-seat"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_booster-seat-description"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_booster-seat-lc-plural"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_booster-seat-lc-singular"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_child-car-seat"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_child-car-seat-description"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_child-car-seat-lc-plural"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_child-car-seat-lc-singular"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_confirm"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_disclaimer"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_edit-request"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_free-badge"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_how-many"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_seat-count"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_subtitle"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_title"));
	registerDynamicTags(t("gt_mig_rides_child-car-seat_title-no-car"));
	registerDynamicTags(t("gt_mig_rides_comments_description"));
	registerDynamicTags(t("gt_mig_rides_comments_description-no-seat"));
	registerDynamicTags(t("gt_mig_rides_comments_placeholder"));
	registerDynamicTags(t("gt_mig_rides_comments_placeholder-no-seat"));
	registerDynamicTags(t("gt_mig_rides_comments_text-area-label"));
	registerDynamicTags(t("gt_mig_rides_comments_text-area-placeholder"));
	registerDynamicTags(t("gt_mig_rides_comments_title"));
	registerDynamicTags(t("gt_mig_rides_error_cta"));
	registerDynamicTags(t("gt_mig_rides_error_generic"));
	registerDynamicTags(t("gt_mig_rides_error_title"));
	registerDynamicTags(t("gt_mig_rides_flight-picker_sub-title-dsa"));
	registerDynamicTags(t("gt_mig_rides_flight-picker_title-dsa"));
	registerDynamicTags(t("gt_mig_rides_free-taxi_free-shout"));
	registerDynamicTags(t("gt_mig_rides_free-taxi_heading-multi-iata"));
	registerDynamicTags(t("gt_mig_rides_free-taxi_heading-single-iata"));
	registerDynamicTags(t("gt_mig_rides_free-taxi_sub-heading-multi-iata"));
	registerDynamicTags(t("gt_mig_rides_free-taxi_sub-heading-single-iata"));
	registerDynamicTags(t("gt_mig_rides_policies_free-cancellation-description"));
	registerDynamicTags(t("gt_mig_rides_policies_free-cancellation-title"));
	registerDynamicTags(t("gt_mig_rides_policies_title"));
	registerDynamicTags(t("gt_mig_rides_route-summary_change-details"));
	registerDynamicTags(t("gt_mig_rides_route-summary_change-route"));
	registerDynamicTags(t("gt_mig_rides_route-summary_close-supplier-info-aria"));
	registerDynamicTags(t("gt_mig_rides_route-summary_estimated-time"));
	registerDynamicTags(t("gt_mig_rides_route-summary_flight-tracking"));
	registerDynamicTags(t("gt_mig_rides_route-summary_flight-tracking-tooltip-description"));
	registerDynamicTags(t("gt_mig_rides_route-summary_flight-tracking-tooltip-heading"));
	registerDynamicTags(t("gt_mig_rides_route-summary_outbound"));
	registerDynamicTags(t("gt_mig_rides_route-summary_powered-by"));
	registerDynamicTags(t("gt_mig_rides_route-summary_provided-by"));
	registerDynamicTags(t("gt_mig_rides_route-summary_remove-return-cta"));
	registerDynamicTags(t("gt_mig_rides_route-summary_return"));
	registerDynamicTags(t("gt_mig_rides_route-summary_show-supplier-info-aria"));
	registerDynamicTags(t("gt_mig_rides_route-summary_suitcases"));
	registerDynamicTags(t("gt_mig_rides_route-summary_supplier-legal-disclaimer"));
	registerDynamicTags(t("gt_mig_rides_route-summary_supplier-legal-entity-name"));
	registerDynamicTags(t("gt_mig_rides_route-summary_supplier-trade-number"));
	registerDynamicTags(t("gt_mig_rides_route-summary_supplier-trade-register-name"));
	registerDynamicTags(t("gt_mig_rides_route-summary_title"));
	registerDynamicTags(t("gt_mig_rides_route-summary_up-to-people"));
	registerDynamicTags(t("gt_mig_rides_route-summary_vehicle"));
	registerDynamicTags(t("gt_mig_rides_route-summary_your-vehicle"));
	// LANDING PAGE
	registerDynamicTags(t("rides_web_landing_title"));
	registerDynamicTags(t("rides_web_landing_subtitle"));
	registerDynamicTags(t("rides_web_landing_usp_header_1"));
	registerDynamicTags(t("rides_web_landing_usp_content_1"));
	registerDynamicTags(t("rides_web_landing_usp_header_2"));
	registerDynamicTags(t("rides_web_landing_usp_content_2"));
	registerDynamicTags(t("rides_web_landing_usp_header_3"));
	registerDynamicTags(t("rides_web_landing_usp_content_3"));
	registerDynamicTags(t("rides_web_sr_meta_title"));
	registerDynamicTags(t("rides_web_landing_carousel_title"));
	registerDynamicTags(t("lpc_rw_how_it_works_header"));
	registerDynamicTags(t("rides_web_landing_airport_taxis_header_1"));
	registerDynamicTags(t("rides_web_landing_airport_taxis_content_1"));
	registerDynamicTags(t("rides_web_landing_airport_taxis_header_2"));
	registerDynamicTags(t("rides_web_landing_airport_taxis_content_2"));
	registerDynamicTags(t("rides_web_landing_airport_taxis_header_3"));
	registerDynamicTags(t("rides_web_landing_airport_taxis_content_3"));
	registerDynamicTags(t("rides_web_landing_faq_title"));
	registerDynamicTags(t("lpc_rw_faqs_sub"));
	registerDynamicTags(t("rides_web_landing_faq_question_1"));
	registerDynamicTags(t("rides_web_landing_faq_question_3"));
	registerDynamicTags(t("rides_web_landing_faq_question_4"));
	registerDynamicTags(t("rides_web_landing_faq_question_2"));
	registerDynamicTags(t("rides_web_landing_faq_answer_1"));
	registerDynamicTags(t("rides_web_landing_faq_answer_3"));
	registerDynamicTags(t("rides_web_landing_faq_answer_2"));
	registerDynamicTags(t("rides_web_landing_faq_answer_4"));
	registerDynamicTags(t("airport_taxi_types", "1", "name"));
	registerDynamicTags(t("airport_taxi_types", "2", "name"));
	registerDynamicTags(t("airport_taxi_types", "3", "name"));
	registerDynamicTags(t("airport_taxi_types", "4", "name"));
	registerDynamicTags(t("airport_taxi_types", "5", "name"));
	registerDynamicTags(t("rides_web_landing_meta_title"));
	registerDynamicTags(t("conf_taxi_ep_passenger_subheader"));
	registerDynamicTags(t("conf_taxi_ep_passenger_subheader"));
	registerDynamicTags(t("rides_web_mybooking_new_cancellation_policy_header"));
	registerDynamicTags(t("rides_web_mybooking_new_cancellation_policy_body"));
	registerDynamicTags(t("rides_web_mybooking_cancellation_policy_local_time"));
	registerDynamicTags(t("rides_web_mybooking_cancellation_policy_outbound"));
	registerDynamicTags(t("a11y_rides_web_mybooking_cancel_rebook_pickup"));
	registerDynamicTags(t("a11y_rides_web_mybooking_cancel_rebook_from"));
	registerDynamicTags(t("a11y_rides_web_mybooking_cancel_rebook_duration"));
	registerDynamicTags(t("a11y_rides_web_mybooking_cancel_rebook_dropoff"));
	registerDynamicTags(t("a11y_rides_web_mybooking_cancel_rebook_to"));
};
