import Orchestrator from "@bookingcom/web-page-orchestrator";
import C360TrackerReact from "@bookingcom/c360-tracker-react";
import type { CustomGoalsForKey, ExperimentName, ExperimentTitle, StagesForKey } from "@btransport/rides-et-middleware";
import { BroadcastableRateResult, RateParams, SelectedVehicle } from "../types/taxi-search-results";
import { Flight } from "../types/flight-picker";
import { DEFAULT_LANGUAGE, DEFAULT_CURRENCY, DEFAULT_AFFILIATE_CODE } from "../constants/defaults";
import {
	COMMENT_METADATA_FIELD_NAMES,
	COMMENT_METADATA_PREFIX,
	COMMENT_METADATA_SEPARATOR,
	COMMENT_METADATA_SEPARATOR_ESCAPED,
} from "../constants/comment-metadata";
import { encryptComments, getReturnRate, trackExperimentGoal, trackExperimentStage } from "./TaxiBookPageClient";
import { isDateWithinWindow } from "../utils/isDateWithinWindow";
import { ET_EXPERIMENTS } from "../constants/et-experiments";
import { FREE_TAXI_CURRENCY } from "../constants/free-taxi";
import { EventPayload, OrchestratorEventPayload } from "../types/gtm-tracking";
import { ChildSeatValues } from "../types/child-seats";
import { replaceNullAndEmptyFieldsWithUndefined } from "../utils/replaceNullWithUndefined";
import { TRANSPORT_TYPES } from "../constants/transport-types";
import { RidesC360Event, C360TravelProductResults, C360TravelProductSearch } from "../utils/c360";
import { hydrateTravelProductResults, hydrateTravelProductSearch } from "./c360";
import { isValidGeniusAffiliateCode } from "../utils/isValidGeniusAffiliateCode";

export type Comments = {
	commentMetadata?: Record<string, string>;
	comments?: string;
	encrypted?: string;
};

type CheckoutParams = {
	affiliate?: string;
	affiliateBookingReference?: string;
	carTypeID: string;
	childSeats?: string;
	comments?: Comments["encrypted"];
	currency: string;
	discount?: string;
	etStateBlob?: string;
	flightNumber?: Flight["flightNumber"];
	lang: string;
	oldExpRedirect?: string;
	originalAffiliate?: string;
	outboundSearchId: BroadcastableRateResult["outbound"]["searchReference"];
	outboundSupplier?: string;
	returnSearchId?: BroadcastableRateResult["inbound"]["searchReference"];
};

export type MarketingParams = {
	adgroupid?: string;
	adplat?: string;
	aid?: string;
	campaignId?: string;
	campaignIdVersion?: string;
	label?: string;
	matchtype?: string;
	offerInstanceId?: string;
	utm_campaign?: string;
	utm_content?: string;
	utm_medium?: string;
	utm_source?: string;
	utm_term?: string;
};

export type MarketingParamsKey = keyof MarketingParams;

export type Experiments = Partial<Record<ExperimentTitle<typeof ET_EXPERIMENTS>, boolean>>;

class TaxiBookPageOrchestrator extends Orchestrator {
	private rateResult: BroadcastableRateResult = undefined;

	private returnRateResult: BroadcastableRateResult = undefined;

	private selectedVehicle: SelectedVehicle = undefined;

	private rateFailureReason: string = undefined;

	private rateParams: RateParams = undefined;

	private flightInfo: Flight = undefined;

	private comments: Comments = {};

	private returnDateTime = {
		returnDate: undefined,
		returnTime: undefined,
	};

	private marketingParams: MarketingParams = undefined;

	private isEncryptionInProgress = false;

	private childSeats: ChildSeatValues = {};

	private discount: string = undefined;

	private affiliateBookingReference: string = undefined;

	private cor: string = undefined;

	private signedIn: boolean = false;
	private geniusLevel: number = undefined;

	private userInitiatedSearchInProgress: boolean = true;

	private c360ResultsSkeleton: C360TravelProductResults = undefined;

	private c360SearchSkeleton: C360TravelProductSearch = undefined;

	private previouslySentC360ResultsSearchID: string = undefined;

	private searchResultsBaseUrl: string = undefined;

	private taxiBookCommands = {
		CONTINUE_TO_CHECKOUT: this.continueToCheckout.bind(this),
		SEND_C360_EVENT: this.sendC360Event.bind(this),
		SEND_GTM_EVENT: this.sendGtmEvent.bind(this),
		TRACK_GOAL: this.trackGoal.bind(this),
		TRACK_STAGE: this.trackStage.bind(this),
		TRIGGER_RATE_FAILED: this.triggerRateFailed.bind(this),
		TRIGGER_SEARCH_FORM_RETURN: this.triggerSearchFormReturn.bind(this),
		UPDATE_AFFILIATE_BOOKING_REFERENCE: this.updateAffiliateBookingReference.bind(this),
		UPDATE_C360_RESULTS_SKELETON: this.updateC360ResultsSkeleton.bind(this),
		UPDATE_C360_SEARCH_SKELETON: this.updateC360SearchSkeleton.bind(this),
		UPDATE_CHILD_SEATS: this.updateChildSeats.bind(this),
		UPDATE_COMMENTS: this.updateComments.bind(this),
		UPDATE_DISCOUNT: this.updateDiscount.bind(this),
		UPDATE_EXPERIMENTS: this.updateExperiments.bind(this),
		UPDATE_FLIGHT_INFO: this.updateFlightInfo.bind(this),
		UPDATE_MARKETING_PARAMS: this.updateMarketingParams.bind(this),
		UPDATE_RATE_PARAMS: this.updateRateParams.bind(this),
		UPDATE_RATE_RESULT: this.updateRateResult.bind(this),
		UPDATE_RETURN_DATE_TIME: this.updateReturnDateTime.bind(this),
		UPDATE_SELECTED_VEHICLE: this.updateSelectedVehicle.bind(this),
	};

	private taxiBookQueries = {
		AFFILIATE_BOOKING_REFERENCE: this.getAffiliateBookingReference.bind(this),
		C360_RESULTS_SKELETON: this.getC360ResultsSkeleton.bind(this),
		C360_SEARCH_SKELETON: this.getC360SearchSkeleton.bind(this),
		CHECKOUT_PARAMS: this.getCheckoutParams.bind(this),
		CHILD_SEATS: this.getChildSeats.bind(this),
		COMMENTS: this.getComments.bind(this),
		DISCOUNT: this.getDiscount.bind(this),
		EXPERIMENTS: this.getExperiments.bind(this),
		FLIGHT_INFO: this.getFlightInfo.bind(this),
		IS_ENCRYPTING: this.getIsEncryptionInProgress.bind(this),
		MARKETING_PARAMS: this.getMarketingParams.bind(this),
		RATE_FAILURE_REASON: this.getRateFailureReason.bind(this),
		RATE_PARAMS: this.getRateParams.bind(this),
		RATE_RESULT: this.getRateResult.bind(this),
		RETURN_RATE: this.getReturnRate.bind(this),
		SELECTED_VEHICLE: this.getSelectedVehicle.bind(this),
		USER_INITIATED_SEARCH_IN_PROGRESS: this.getUserInitiatedSearchInProgress.bind(this),
	};

	private experiments: Experiments = {};

	constructor() {
		super();

		this.cor = window.orchestratorInitData?.cor;
		this.signedIn = window.orchestratorInitData?.isSignedIn;
		this.geniusLevel = window.orchestratorInitData?.geniusLevel;
		this.discount = window.orchestratorInitData?.discount;
		this.searchResultsBaseUrl = window.orchestratorInitData?.searchResultsBaseUrl;

		this.addCommands(this.taxiBookCommands);
		this.addQueries(this.taxiBookQueries);

		this.setupEvents();
	}

	setupEvents(): void {
		this.on("RATE_RESULT_UPDATED", async (rateResult: BroadcastableRateResult): Promise<void> => {
			if (
				!rateResult.outbound.pickupLocation.isAirport ||
				!isDateWithinWindow(rateResult.outbound.requestedPickupDateTime, "<=", 48)
			) {
				return;
			}

			document.getElementById("flightPickerContainer")?.classList?.add("flight-picker--withAlert");
		});
	}

	async send360SearchEvent(): Promise<void> {
		const travelProductSearchPayload = hydrateTravelProductSearch(this.c360SearchSkeleton, this.rateParams);

		await this.runCommand("UPDATE_C360_RESULTS_SKELETON", {
			...this.c360ResultsSkeleton,
			content: {
				...this.c360ResultsSkeleton?.content,
				search_id: travelProductSearchPayload?.content?.search_id,
			},
		});

		await this.runCommand("SEND_C360_EVENT", travelProductSearchPayload);
	}

	async sendGtmEvent(payload: OrchestratorEventPayload): Promise<void> {
		const queryParams = new URLSearchParams(window.location.search);
		const dataLayerObject: EventPayload = {
			adplat: this.marketingParams?.adplat,
			affiliate_code: queryParams.get("affiliate"),
			car_type: TRANSPORT_TYPES[this.selectedVehicle?.carTypeResultReference?.toString()],
			cor: this.cor,
			dropoff_date: this.rateResult?.outbound?.requestedPickupDateTime,
			dropoff_iata: this.rateResult?.outbound?.dropoffLocation?.airportCode,
			dropoff_location: `${this.rateResult?.outbound?.dropoffLocation?.city} ${this.rateResult?.outbound?.dropoffLocation?.country}`,
			dropoff_location_type: this.rateResult?.outbound?.dropoffLocation?.locationType,
			flight_arrival_time: this.flightInfo?.flightArrivalTime,
			funnel: this.discount === "FREE_TAXI" ? "Free-Taxi" : "Core",
			genius_level: this.geniusLevel,
			logged_status: this.signedIn,
			page_category: "Selection Page",
			passengers: this.rateResult?.outbound?.passenger?.toString(),
			pickup_date: this.rateResult?.outbound?.requestedPickupDateTime,
			pickup_iata: this.rateResult?.outbound?.pickupLocation?.airportCode,
			pickup_location: `${this.rateResult?.outbound?.pickupLocation?.city} ${this.rateResult?.outbound?.pickupLocation?.country}`,
			pickup_location_type: this.rateResult?.outbound?.pickupLocation?.locationType,
			pickup_time: this.rateResult?.outbound?.requestedPickupDateTime?.split(" ")[1],
			product: "taxi",
			return: this.rateResult?.isReturn,
			site_language: queryParams.get("lang"),
			timestamp: Date.now().toString(),
			transport_type: "Taxi",
			user_id: undefined,
			...payload.extraFields,
		};
		const cleanDataLayerObject = replaceNullAndEmptyFieldsWithUndefined(dataLayerObject);
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: payload.eventName,
			...(cleanDataLayerObject as unknown as Record<string, string>),
		});
	}

	async updateRateResult(rateResult: BroadcastableRateResult): Promise<void> {
		this.rateResult = rateResult;

		if (this.rateParams !== undefined) {
			this.rateParams.pickupEstablishment = rateResult.outbound.pickupLocation.establishmentName;
			this.rateParams.dropoffEstablishment = rateResult.outbound.dropoffLocation.establishmentName;
		}

		if (this.userInitiatedSearchInProgress === true) {
			this.userInitiatedSearchInProgress = false;
			await this.sendGtmEvent({
				eventName: "search",
				extraFields: { number_of_results: rateResult?.outbound?.results?.length },
			});
		}

		if (
			!this.previouslySentC360ResultsSearchID ||
			this.c360ResultsSkeleton?.content?.search_id !== this.previouslySentC360ResultsSearchID
		) {
			const travelProductResultsPayload = hydrateTravelProductResults(this.c360ResultsSkeleton, rateResult);
			await this.runCommand("SEND_C360_EVENT", travelProductResultsPayload);
			this.previouslySentC360ResultsSearchID = travelProductResultsPayload?.content?.search_id;
		}

		this.publishRateResult();
	}

	// eslint-disable-next-line class-methods-use-this
	async sendC360Event(eventPayload: RidesC360Event): Promise<void> {
		try {
			await C360TrackerReact.sendEvent(eventPayload);
		} catch (error) {
			console.error({
				logTag: "C360",
				message: "send error",
				error,
			});
		}
	}

	async updateC360ResultsSkeleton(skeletonResultsPayload: C360TravelProductResults): Promise<void> {
		const currentSkeleton = this.c360ResultsSkeleton;
		this.c360ResultsSkeleton = {
			...currentSkeleton,
			...skeletonResultsPayload,
			content: {
				...currentSkeleton?.content,
				...skeletonResultsPayload?.content,
			},
		};
	}

	async updateC360SearchSkeleton(skeletonSearchPayload: C360TravelProductSearch): Promise<void> {
		this.c360SearchSkeleton = skeletonSearchPayload;
		this.on("RATE_PARAMS_UPDATED", async (): Promise<void> => {
			this.send360SearchEvent();
		});

		if (this.rateParams) {
			this.send360SearchEvent();
		}
	}

	async updateSelectedVehicle(selectedVehicle: SelectedVehicle): Promise<void> {
		this.selectedVehicle = selectedVehicle;
		this.publishSelectedVehicle();
	}

	async triggerRateFailed(failureReason: string): Promise<void> {
		this.rateFailureReason = failureReason;

		if (this.userInitiatedSearchInProgress === true) {
			this.userInitiatedSearchInProgress = false;
			await this.sendGtmEvent({
				eventName: "search_error",
				extraFields: { error_type: failureReason, error_message: failureReason },
			});
		}

		this.publishRateFailed();
	}

	async updateRateParams(rateParams: RateParams): Promise<void> {
		this.rateParams = rateParams;

		if (!rateParams.shouldTriggerPageRefresh) {
			this.userInitiatedSearchInProgress = true;
			this.publishRateParams();
			return;
		}

		this.publishPageRefresh();
	}

	async updateFlightInfo(flightInfo: Flight | null): Promise<void> {
		this.flightInfo = flightInfo || undefined;

		this.publishFlightInfo();

		if (flightInfo?.flightArrivalDateTime) {
			return this.updatePickUpTime(flightInfo.flightArrivalDateTime.replace(".000", ""));
		}
	}

	async updatePickUpTime(pickUpTime: string): Promise<void> {
		if (pickUpTime === this.rateParams?.pickupDateTime) {
			return;
		}

		this.rateParams = {
			...this.rateParams,
			pickupDateTime: pickUpTime,
		};

		this.publishRateParams();
	}

	async updateChildSeats(updatedChildSeatsInfo: ChildSeatValues = {}): Promise<void> {
		this.childSeats = updatedChildSeatsInfo;

		// eslint-disable-next-line unicorn/no-array-reduce
		this.comments.commentMetadata = Object.entries(updatedChildSeatsInfo).reduce(
			(metadata, [seatType, seatCount]) => {
				const updatedMetadata = { ...metadata };
				const fieldName = COMMENT_METADATA_FIELD_NAMES[seatType];

				if (metadata[fieldName] && seatCount < 1) {
					delete updatedMetadata[fieldName];
				} else {
					updatedMetadata[fieldName] = seatCount.toString();
				}

				return updatedMetadata;
			},
			{},
		);

		this.publishUpdateChildSeats();
		await this.updateComments(undefined, true);
	}

	async triggerSearchFormReturn(): Promise<void> {
		this.publishTriggerSearchFormReturn();
	}

	async continueToCheckout(baseUrl: string): Promise<void> {
		if (await this.getIsEncryptionInProgress()) {
			await new Promise<void>((resolve) => {
				this.on("COMMENTS_UPDATED", () => {
					resolve();
				});
			});
		}

		const checkoutSearchParams = new URLSearchParams(await this.getCheckoutParams()).toString();

		const checkoutPath = `/checkout?${checkoutSearchParams}`;

		if (!baseUrl) {
			return window.location.assign(checkoutPath);
		}

		window.location.assign(`${baseUrl}${checkoutPath}`);
	}

	private getCommentMetadataLines(shouldIncludeNewPrefix: boolean): string[] {
		return this.comments.commentMetadata
			? [
					...(shouldIncludeNewPrefix ? [COMMENT_METADATA_PREFIX] : []),
					...Object.entries(this.comments.commentMetadata).flatMap(([key, value]) => {
						if (value === "0") {
							return [];
						}

						return [`${key}: ${value}`, ` ${COMMENT_METADATA_SEPARATOR} `];
					}),
				]
			: [];
	}

	async updateComments(comments?: string, forceUpdate = false): Promise<void> {
		const genericChildSeatSelected = Boolean(
			this.comments.commentMetadata?.[COMMENT_METADATA_FIELD_NAMES["generic-child-seat"]],
		);
		const atLeastOneSeatSelected = Object.values(this.comments.commentMetadata || {}).some(
			(value) => value !== "0",
		);

		if (this.comments.commentMetadata && Object.keys(this.comments.commentMetadata).length === 0) {
			delete this.comments.commentMetadata;
		}

		let encryptedComments: string;
		if (forceUpdate || (comments && comments !== "") || (comments === "" && this.comments.commentMetadata)) {
			this.isEncryptionInProgress = true;
			const commentText = `${comments ?? this.comments?.comments ?? ""}`.replaceAll(
				COMMENT_METADATA_SEPARATOR,
				COMMENT_METADATA_SEPARATOR_ESCAPED,
			);

			const commentField =
				genericChildSeatSelected || !atLeastOneSeatSelected
					? commentText.length > 0
						? commentText
						: ""
					: commentText.length > 0
						? `${COMMENT_METADATA_FIELD_NAMES["traveller-comments"]}: ${commentText}`
						: "";

			encryptedComments = await encryptComments(
				[
					...this.getCommentMetadataLines(!genericChildSeatSelected && atLeastOneSeatSelected),
					commentField,
				].join(""),
			);
		}

		this.comments = {
			...this.comments,
			encrypted: encryptedComments,
			comments: forceUpdate && !comments ? this.comments.comments : comments,
		};

		this.isEncryptionInProgress = false;
		this.publishComments();
	}

	async updateReturnDateTime({ returnDate, returnTime }): Promise<void> {
		this.returnDateTime = {
			...this.returnDateTime,
			returnDate,
			returnTime,
		};

		this.publishReturnDateTime();
	}

	async updateDiscount(discount: string): Promise<void> {
		this.discount = discount;
	}

	async updateAffiliateBookingReference(affiliateBookingReference: string): Promise<void> {
		this.affiliateBookingReference = affiliateBookingReference;
	}

	async updateMarketingParams(marketingParams: MarketingParams): Promise<void> {
		this.marketingParams = marketingParams;
	}

	async updateExperiments(experiments: Experiments): Promise<void> {
		this.experiments = { ...this.experiments, ...experiments };

		this.publishExperiments();
	}

	// eslint-disable-next-line class-methods-use-this
	async trackGoal<N extends ExperimentName<typeof ET_EXPERIMENTS>>({
		experiment,
		goal,
	}: {
		experiment: N;
		goal: CustomGoalsForKey<typeof ET_EXPERIMENTS, N>;
	}): Promise<void> {
		await trackExperimentGoal(ET_EXPERIMENTS, experiment, goal);
	}

	// eslint-disable-next-line class-methods-use-this
	async trackStage<N extends ExperimentName<typeof ET_EXPERIMENTS>>({
		experiment,
		stage,
	}: {
		experiment: N;
		stage: StagesForKey<typeof ET_EXPERIMENTS, N>;
	}): Promise<void> {
		await trackExperimentStage(ET_EXPERIMENTS, experiment, stage);
	}

	async getRateResult(): Promise<BroadcastableRateResult> {
		return this.rateResult;
	}

	async getUserInitiatedSearchInProgress(): Promise<boolean> {
		return this.userInitiatedSearchInProgress;
	}

	async getIsEncryptionInProgress(): Promise<boolean> {
		return this.isEncryptionInProgress;
	}

	async getSelectedVehicle(): Promise<SelectedVehicle> {
		return this.selectedVehicle;
	}

	async getRateFailureReason(): Promise<string> {
		return this.rateFailureReason;
	}

	async getRateParams(): Promise<RateParams> {
		return this.rateParams;
	}

	async getFlightInfo(): Promise<Flight> {
		return this.flightInfo;
	}

	async getComments(): Promise<Comments> {
		return this.comments;
	}

	async getDiscount(): Promise<string> {
		return this.discount;
	}

	async getAffiliateBookingReference(): Promise<string> {
		return this.affiliateBookingReference;
	}

	async getReturnRate(): Promise<BroadcastableRateResult> {
		const queryParams = new URLSearchParams(window.location.search);

		const affiliate = isValidGeniusAffiliateCode(queryParams?.get("affiliate"))
			? queryParams?.get("affiliate")
			: queryParams?.get("affiliateCode") || queryParams?.get("affiliate") || DEFAULT_AFFILIATE_CODE;

		this.returnRateResult = await getReturnRate(this.searchResultsBaseUrl, {
			affiliate,
			currency: queryParams?.get("currency"),
			dropoff: queryParams?.get("dropoff"),
			dropoffEstablishment: queryParams?.get("dropoffEstablishment"),
			dropoffType: queryParams?.get("dropoffType"),
			language: queryParams?.get("lang"),
			passengers: Number(queryParams?.get("passengers")),
			pickup: queryParams.get("pickup"),
			pickupDate: new Date(`${queryParams.get("date")}T${queryParams.get("time")}:00`),
			pickupEstablishment: queryParams?.get("pickupEstablishment"),
			pickupType: queryParams?.get("pickupType"),
			returnDate: new Date(`${this.returnDateTime.returnDate}T${this.returnDateTime.returnTime}:00`),
		});

		return this.returnRateResult;
	}

	async getCheckoutParams(): Promise<CheckoutParams & MarketingParams> {
		const queryParams = new URLSearchParams(window.location.search);

		const checkoutParams: CheckoutParams = {
			carTypeID: this.selectedVehicle?.carTypeResultReference.toString(),
			currency: queryParams.get("currency") || DEFAULT_CURRENCY,
			lang: queryParams.get("lang") || DEFAULT_LANGUAGE,
			outboundSearchId: this.rateResult?.outbound.searchReference,
			outboundSupplier: this.selectedVehicle?.supplierName,
		};

		const affiliate = isValidGeniusAffiliateCode(queryParams.get("affiliate"))
			? queryParams.get("affiliate")
			: queryParams.get("affiliateCode") || queryParams.get("affiliate");

		if (affiliate) {
			checkoutParams.affiliate = affiliate;
		}

		const originalAffiliate = queryParams.get("originalAffiliate");
		if (originalAffiliate) {
			checkoutParams.originalAffiliate = originalAffiliate;
		}

		const oldExpRedirect = queryParams.get("oldExpRedirect");
		if (oldExpRedirect) {
			checkoutParams.oldExpRedirect = oldExpRedirect;
		}

		if (this.affiliateBookingReference) {
			checkoutParams.affiliateBookingReference = this.affiliateBookingReference;
		}

		const totalSeats = Object.values(this.childSeats).reduce((a, b) => a + b, 0);
		if (totalSeats > 0) {
			checkoutParams.childSeats = JSON.stringify(this.childSeats);
		}

		if (this.comments?.encrypted) {
			checkoutParams.comments = this.comments.encrypted;
		}

		if (this.marketingParams?.offerInstanceId) {
			checkoutParams.currency = FREE_TAXI_CURRENCY;
		}

		if (this.discount) {
			checkoutParams.discount = this.discount;
		}

		const etStateBlob = queryParams.get("etStateBlob");
		if (etStateBlob) {
			checkoutParams.etStateBlob = etStateBlob;
		}

		if (this.flightInfo?.flightNumber) {
			checkoutParams.flightNumber = this.flightInfo.flightNumber;
		}

		if (this.rateResult?.inbound) {
			checkoutParams.returnSearchId = this.rateResult.inbound.searchReference;
		}

		return { ...checkoutParams, ...this.marketingParams };
	}

	async getMarketingParams(): Promise<MarketingParams> {
		return this.marketingParams;
	}

	async getExperiments(): Promise<Experiments> {
		return this.experiments;
	}

	async getChildSeats(): Promise<ChildSeatValues> {
		return this.childSeats;
	}

	async getC360ResultsSkeleton(): Promise<C360TravelProductResults> {
		return this.c360ResultsSkeleton;
	}

	async getC360SearchSkeleton(): Promise<C360TravelProductSearch> {
		return this.c360SearchSkeleton;
	}

	publishRateResult(): void {
		this.publish("RATE_RESULT_UPDATED", this.rateResult);
	}

	publishSelectedVehicle(): void {
		this.publish("SELECTED_VEHICLE_UPDATED", this.selectedVehicle);
	}

	publishRateFailed(): void {
		this.publish("RATE_FAILED", this.rateFailureReason);
	}

	publishRateParams(): void {
		this.publish("RATE_PARAMS_UPDATED", this.rateParams);
	}

	publishTriggerSearchFormReturn(): void {
		this.publish("TRIGGER_SEARCH_FORM_RETURN", true);
	}

	publishFlightInfo(): void {
		this.publish("FLIGHT_INFO_UPDATED", this.flightInfo);
	}

	publishPageRefresh(): void {
		this.publish("PAGE_REFRESH", {
			...this.rateParams,
			...(this.selectedVehicle?.carTypeResultReference
				? { preSelectedResultReference: `${this.selectedVehicle.carTypeResultReference}` }
				: {}),
		});
	}

	publishComments(): void {
		this.publish("COMMENTS_UPDATED", this.comments);
	}

	publishReturnDateTime(): void {
		this.publish("RETURN_DATE_TIME_UPDATED", this.returnDateTime);
	}

	publishExperiments(): void {
		this.publish("EXPERIMENTS_UPDATED", this.experiments);
	}

	publishUpdateChildSeats(): void {
		this.publish("CHILD_SEATS_UPDATED", this.childSeats);
	}
}

export { TaxiBookPageOrchestrator };
