import type { BookingDetailsExpectedParameters } from "../../pages/BookingDetailsPage/BookingDetailsPage.types";
import { removeTrailingSlash } from "../removeTrailingSlash";

/**
 * Deletes a specified search parameter from the current URL and updates the browser's history state.
 *
 * @param {keyof BookingDetailsExpectedParameters} paramName - The name of the search parameter to delete.
 * @returns {void}
 *
 * @description
 * - Uses `URLSearchParams` to remove the specified parameter from the current URL's query string.
 * - Updates the browser's history state using `window.history.replaceState` to reflect the updated URL without reloading the page.
 * - Ensures the URL's pathname does not end with a trailing slash by using `removeTrailingSlash`.
 *
 * @example
 * // Current URL: https://example.com/path/?foo=bar&baz=qux
 * deleteSearchParam("foo");
 * // Updated URL: https://example.com/path/?baz=qux
 */
export const deleteSearchParam = (paramName: keyof BookingDetailsExpectedParameters): void => {
	const currentSearchParams = new URLSearchParams(window.location.search);
	currentSearchParams.delete(paramName);

	window.history.replaceState(
		undefined,
		"",
		`${removeTrailingSlash(window.location.pathname)}/?${currentSearchParams.toString()}`,
	);
};
