import { format } from "date-fns";
import type { LocaleObject } from "../getDateFnsLocale";

/**
 * Formats a date as "Day, Day Month" (e.g., "Mon, 8 Jan").
 *
 * @param {Date} dateToFormat - The date to format.
 * @param {LocaleObject} [locale] - The optional locale object for localisation.
 * @returns {string} The formatted date string.
 *
 * @example
 * const formattedDate = formatDayWithMonth(new Date("2025-01-08"));
 * console.log(formattedDate); // "Wed, 8 Jan" (based on locale)
 */
function formatDayWithMonth(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "eee, d MMM", locale);
}

/**
 * Formats a date as "HH:mm" (24-hour time, e.g., "15:30").
 *
 * @param {Date} dateToFormat - The date to format.
 * @param {LocaleObject} [locale] - The optional locale object for localisation.
 * @returns {string} The formatted time string.
 *
 * @example
 * const formattedTime = formatTime(new Date("2025-01-08T15:30:00"));
 * console.log(formattedTime); // "15:30" (based on locale)
 */
function formatTime(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "HH:mm", locale);
}

/**
 * Formats a date as "Day, Day Month, HH:mm" (e.g., "Wed, 8 Jan, 15:30").
 *
 * @param {Date} dateToFormat - The date to format.
 * @param {LocaleObject} [locale] - The optional locale object for localisation.
 * @returns {string} The formatted date and time string.
 *
 * @example
 * const formattedDateTime = formatDayWithMonthAndTime(new Date("2025-01-08T15:30:00"));
 * console.log(formattedDateTime); // "Wed, 8 Jan, 15:30" (based on locale)
 */
function formatDayWithMonthAndTime(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "eee, d MMM, HH:mm", locale);
}

/**
 * Formats a date as "YYYY-MM-DD" (e.g., "2025-01-08").
 *
 * @param {Date} dateToFormat - The date to format.
 * @param {LocaleObject} [locale] - The optional locale object for localisation.
 * @returns {string} The formatted date string.
 *
 * @example
 * const formattedDate = formatYearMonthDay(new Date("2025-01-08"));
 * console.log(formattedDate); // "2025-01-08" (based on locale)
 */
function formatYearMonthDay(dateToFormat: Date, locale?: LocaleObject): string {
	return format(dateToFormat, "yyyy-MM-dd", locale);
}

export { formatDayWithMonth, formatTime, formatDayWithMonthAndTime, formatYearMonthDay };
